import * as jose from 'jose'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { User } from '../definitions'
import { router } from '../router'

export const useUser = defineStore('user', {
  state: () => {
    return {
      currentUser: null as User | null,
    }
  },
  getters: {
    isLoggedIn: (state) => state.currentUser !== null,
    hasRoles: (state) => (roles: string[]) => roles.some((role: string) => state.currentUser?.roles.includes(role)),
  },
  actions: {
    async setCurrentUserFromToken(token: string) {
      const spki = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzYWCgsh26Y1e7mNtwXRxO2sgCqz//Gn3kLxnLjV55hml5mw+tZWQ3Y1Ea/KYxCXlfCcduvzO7dfMKlnWS56uvAqr0Z9KtMTTQqXiIFJfyn6iPpPKpAEDgzd3EX1uOLl8Sh+UHfhSqLqfW1hvaD1vb7uRchV1xqPmhjIyiiG1s7LW0mH2APSYdY6r4c9YSTnHywpwBtResj7ninw3abiJnEOrGap4GMPtp5DnV3ie0hciSrNIaVp4sbJRWTIt2thjBPwUWxLBv8wg2C4dkDz387dvIjeuO9bkaoQj/o1EutRVJandKZpSfTd9edgYCDCpOL0Zs8OvqOz8JSsbYHBFhQIDAQAB-----END PUBLIC KEY-----"
      const publicKey = await jose.importSPKI(spki, 'RS256')
      const { payload } = await jose.jwtVerify(token, publicKey, { issuer: 'locacore' })

      localStorage.setItem('LOCA_PORTAL_ACCESS_TOKEN', token)

      this.currentUser = {
        id: payload.id as string,
        name: payload.name as string,
        roles: payload.roles as string[],
      }
    },
    async logout() {
      await router.push({ path: '/login' })
      localStorage.removeItem('LOCA_PORTAL_ACCESS_TOKEN')
      this.currentUser = null
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUser, import.meta.hot))
}
