import { acceptHMRUpdate, defineStore } from 'pinia'

export const useApp = defineStore('app', {
  state: () => {
    return {
      isLoading: false as boolean,
    }
  },
  actions: {
    showLoading(message: string | undefined = undefined) {
      this.isLoading = true
    },
    hideLoading() {
      this.isLoading = false
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApp, import.meta.hot))
}
