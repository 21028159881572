import { createPinia } from 'pinia'
import 'sweetalert2/src/sweetalert2.scss'
import { createApp } from 'vue'
import './app.scss'
import App from './App.vue'
import { router } from './router'
import 'v-calendar/dist/style.css'

import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  enabled: import.meta.env.VITE_APP_ENABLE_SENTRY === 'true',
  dsn: 'https://899da099c9e749cbb402a7bb7e32bad0@o76990.ingest.sentry.io/6256883',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

createApp(App)
  .use(createPinia())
  .use(router)
  .use(PrimeVue)
  .mount('#app')
