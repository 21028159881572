<script setup lang="ts">
import { useApp } from '../../stores/app';

const appStore = useApp()
</script>

<template>
  <div class="w-screen h-screen absolute top-0 left-0 z-[999] bg-black/30 flex items-center justify-center" v-if="appStore.isLoading">
    <div class="shadow-lg rounded-full bg-white w-[90px] h-[90px] flex items-center justify-center">
      <div class="lds-dual-ring"></div>
    </div>
  </div>
</template>

<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
