import { createRouter, createWebHistory } from 'vue-router'
import { useUser } from './stores/user'

/** ================================================================================ **/

const insuranceRoutes = [
  {
    path: '/insurance/hospitals',
    component: () => import('./views/insurance/HospitalList.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
  {
    path: '/insurance/orders',
    component: () => import('./views/insurance/OrderList.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
  {
    path: '/insurance/orders/:insuranceCode',
    component: () => import('./views/insurance/OrderDetail.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
  {
    path: '/insurance/package-groups',
    component: () => import('./views/insurance/PackageGroupList.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
  {
    path: '/insurance/package-groups/:id',
    component: () => import('./views/insurance/PackageGroupDetail.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
  {
    path: '/insurance/vehicle-profiles',
    component: () => import('./views/insurance/VehicleProfileList.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
  {
    path: '/insurance/health-profiles',
    component: () => import('./views/insurance/HealthProfileList.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
  {
    path: '/insurance/travel-profiles',
    component: () => import('./views/insurance/TravelProfileList.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
]

/** ================================================================================ **/

const rewardRoutes = [
  {
    path: '/reward/rewards',
    component: () => import('./views/reward/RewardList.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
]

/** ================================================================================ **/

const settingRoutes = [
  {
    path: '/settings/exchange-rates',
    component: () => import('./views/settings/ExchangeRate.vue'),
    meta: {
      requiresAuth: true,
      roles: ['admin'],
    }
  },
]

/** ================================================================================ **/

const _router = createRouter({
  history: createWebHistory(),
  routes: [
    ...insuranceRoutes,
    ...rewardRoutes,
    ...settingRoutes,
    { path: '/', redirect: '/insurance/orders' },
    { path: '/login', component: () => import('./views/Login.vue') },
    { path: '/:pathMatch(.*)*', redirect: '/' }
  ],
})

/** ================================================================================ **/

_router.beforeEach(async (to, from, next) => {
  const userStore = useUser()

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // TRY TO LOAD USER FROM TOKEN
    if (!userStore.isLoggedIn) {
      const token = localStorage.getItem('LOCA_PORTAL_ACCESS_TOKEN')
      if (token) {
        try {
          await userStore.setCurrentUserFromToken(token)
        } catch (error) {
          console.error('Cannot set user from remembered access token', error)
        }
      }
    }

    // RE-CHECK LOGIN STATUS
    if (!userStore.isLoggedIn) {
      return next({ path: '/login' })
    }

    // CHECK ROLES
    const routeRoles = to.meta !== undefined && to.meta.roles !== undefined ? to.meta.roles : []
    if (!userStore.hasRoles(routeRoles)) {
      return next({ path: '/forbidden' })
    }

    return next()
  } else {
    next()
  }
})

/** ================================================================================ **/

export const router = _router
